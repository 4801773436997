export const wrapper = {
  my: 3,
}

export const title = {
  mb: 1,
  fontSize: 0,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: 'sidebar.navGroup',
}
